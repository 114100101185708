<template>
    <main>
        <div class="hero" :style="{ backgroundImage: `linear-gradient(rgba(0 0 0 / 0.3), rgba(0 0 0 / 0.3)), url(${(formImagesItem.image!==null?formImagesItem.image:formImagesItemDefault.image)})` }">
           <div v-if="formImagesItem" class="container container--xs text-center">
                <h1>{{formImagesItem.title}}</h1>
                <p>{{formImagesItem.subtitle}}</p>
           </div>
        </div>
        <section class="section pt-0">
            <div class="container">
                <p class="breadcrumb"><a href="/"><span class="icon-home-outline"></span></a> / <span>Afilia tu tienda</span></p>
                <h2><a href="#" @click="router.go(-1)"><img src="images/arrowleft.svg" alt="Club de Sastreria Barrington"></a> Afilia tu tienda</h2>
                <div class="py-lg pt-0">
                    <p>Se parte de nuestra red "Barrington Club de Sastrería", tan pronto ingreses tu información iniciaremos el proceso de validación para que completes el proceso al e-mail que registres.</p>    
                </div>
                <!-- jkjjkjkjjkjkjjkjk -->
                
                <form action="" class="form container container--xs" @submit.prevent="onSubmit">
                    <input type="hidden" v-model="form.from_public">
                    <div class="form__control">
                        <label class="form__label form__label--required">Razón Social :</label>
                        <input type="text" class="form__field" v-model="form.business_name">
                    </div>
                    <p class="form__alert" v-if="messageError.errors.business_name">
                            {{ messageError.errors.business_name[0] }}
                    </p>
                    
                    <div class="form__control">
                        <label class="form__label form__label--required">Nombre Comercial :</label>
                        <input type="text" class="form__field" placeholder="" v-model="form.tradename">
                    </div>
                    <p class="form__alert" v-if="messageError.errors.tradename">
                            {{ messageError.errors.tradename[0] }}
                    </p>

                    <div class="form__control">
                        <label class="form__label form__label--required form__label--error">Móvil :</label>
                        <div class="form__control__row">
                            <div>
                                <select  class="form__field form__field--select">
                                    <option value="54">Argentina (54+)</option>
                                    <option value="591">Bolivia (591+)</option>
                                    <option value="56">Chile (56+)</option>
                                    <option value="51">Perú (51+)</option>
                                </select>
                            </div>
                            <div>
                                <input type="tel" v-model="form.phone_movil" class="form__field" placeholder="">
                            </div>
                            
                        </div>
                         <p class="form__alert" v-if="messageError.errors.non_field_errors">
                            {{ messageError.errors.non_field_errors[0] }}
                        </p>
                    </div>
                   
                    
                    <div class="form__control">
                        <label class="form__label form__label--required form__label--error">Email :</label>
                        <input type="email" class="form__field" v-model="form.email" placeholder="usuarioEmail@email.com">
                        <p class="form__alert" v-if="messageError.errors.email">
                            {{ messageError.errors.email[0] }}
                        </p>
                            
                    </div>
                    

                    <button class="cta">Enviar por aprobación</button>
                    
                </form>

                <!-- hahshajjasjkkjasj -->
            </div>
        </section>
    </main>
</template>

<script>

//import Loading from 'vue-loading-overlay';
//import 'vue-loading-overlay/dist/vue-loading.css';

import { ref, computed } from "vue";
import router from '@/router/index';
import API from "@/api";

export default {
    name: "AffiliateYourStore",
    components: {
        //Loading
    },
    
    setup(props, context) {
        var isLoading = ref(false)
        const fullPage = true
    
        const form = ref({ 
            business_name: "",
            tradename: "",
            email: "",
            phone: "",
            phone_movil: "",
            from_public: true
        });        

        const messagePost = ref([])
        const messageError = ref({errors:{}})        

        const onSubmit = () => {            
            isLoading.value = true
            console.log(isLoading)
            API.post("/api/shop", form.value)
            .then(
                response => {
                    isLoading.value = false
                    console.log(isLoading)
                    messagePost.value = response.data
                    router.push({ name: 'AffiliateSuccess' })
                }
            )
            .catch(error => {      
                isLoading.value = false          
                messageError.value = error.response.data;            
            });
        }
        const formImagesItem = ref([]);
        const formImagesItemDefault = ref([]);
        const getDefault = () =>{
            API.get("/api/head-global-page-internal").then(response => { 
                formImagesItemDefault.value.image =  response.data[0].image===null?null:process.env.VUE_APP_BASE_URL +  response.data[0].image;
            })
        }
        const getHeaderDetail = () => {      
            API.get('/api/image-head-page').then(response => {      
                formImagesItem.value = response.data.filter(x=> x.head_menu_navigation_detail===4)[0];
                    console.log('x',formImagesItem.value)

                if (formImagesItem.value!=undefined){
                    formImagesItem.value.image =  response.data[0].image===null?null:process.env.VUE_APP_BASE_URL + formImagesItem.value.image;
                }
            });
        }           
        
        return {    
            isLoading,
            fullPage,   
            router,
            messageError,
            form,
            onSubmit,
            formImagesItem, formImagesItemDefault, getDefault, getHeaderDetail 
        }
    },
    mounted() {
        this.getDefault()
        this.getHeaderDetail();
    }
}
</script>